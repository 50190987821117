var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-manage" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-lg-7" }, [
          _vm._m(0),
          _c("h1", [_vm._v("ユーザー登録")]),
          _c("p", [
            _vm._v(
              "ご入力いただいたメールアドレスに「認証コード」を送信します。迷惑メールフィルダをお使いの場合は、「@yamalab.jp」からのメールを受信できるように設定をお願いします。"
            )
          ]),
          _vm.message
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v(_vm._s(_vm.message) + " ")
              ])
            : _vm._e(),
          _vm.errMessage
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v("システムエラーが発生しました。"),
                _c(
                  "div",
                  [
                    _vm._v(
                      "しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが"
                    ),
                    _c(
                      "router-link",
                      { attrs: { to: "/inquiry_form/input" } },
                      [_vm._v("こちら")]
                    ),
                    _vm._v("よりをお問い合わせください。")
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "form",
            {
              staticClass: "needs-validation",
              attrs: { autocomplete: "off", novalidate: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.exec($event)
                }
              }
            },
            [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "mail" } }, [
                  _vm._v("メールアドレス")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mail,
                      expression: "mail"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "email",
                    placeholder: "メールアドレス",
                    required: ""
                  },
                  domProps: { value: _vm.mail },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.mail = $event.target.value
                    }
                  }
                }),
                _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v("メールアドレスを入力してください")
                ])
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "password" } }, [
                  _vm._v("パスワード")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password,
                      expression: "password"
                    }
                  ],
                  ref: "password",
                  staticClass: "form-control",
                  attrs: {
                    id: "password",
                    type: "password",
                    minlength: "8",
                    maxlength: "16",
                    placeholder: "パスワード(8~16文字の大小英数字)",
                    required: ""
                  },
                  domProps: { value: _vm.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.password = $event.target.value
                    }
                  }
                }),
                _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v("パスワードを8~16文字で入力してください")
                ]),
                _c("div", [_vm._v(_vm._s(_vm.textCount) + "/16")]),
                _c("div", { staticClass: "small" }, [
                  _vm._v("※大小英数字8~16文字で入力してください")
                ])
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "p",
                  [
                    _vm._v("登録には"),
                    _c("router-link", { attrs: { to: "/info/terms" } }, [
                      _vm._v("利用規約")
                    ]),
                    _vm._v("と"),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: "/info/contact-privacy",
                          target: "_blank",
                          rel: "noopener"
                        }
                      },
                      [_vm._v("個人情報の取り扱いについて")]
                    ),
                    _vm._v("への同意が必要です")
                  ],
                  1
                )
              ]),
              _c("input", {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "submit",
                  value: "規約に同意して次へ",
                  disabled: _vm.isDisabled
                }
              })
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center m-4" }, [
      _c("img", { attrs: { src: "/img/register/status_01.png" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }