<template lang="pug">
  .user-manage
    .container
      .row.justify-content-md-center
        .col-lg-7
          .text-center.m-4
            img(src="/img/register/status_01.png")
          h1 ユーザー登録
          p ご入力いただいたメールアドレスに「認証コード」を送信します。迷惑メールフィルダをお使いの場合は、「@yamalab.jp」からのメールを受信できるように設定をお願いします。
          .alert.alert-danger(v-if="message") {{ message }} 
          .alert.alert-danger(v-if="errMessage") システムエラーが発生しました。
            div しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが
              router-link(to="/inquiry_form/input") こちら
              |よりをお問い合わせください。
          form.needs-validation(@submit.prevent="exec" autocomplete="off" novalidate)
            .form-group
              label(for="mail") メールアドレス
              input.form-control(type="email" placeholder="メールアドレス" v-model="mail" required)
              .invalid-feedback メールアドレスを入力してください
            .form-group
              label(for="password") パスワード
              input#password.form-control(type="password" minlength='8' maxlength='16' placeholder="パスワード(8~16文字の大小英数字)" v-model="password" ref="password" required)
              .invalid-feedback パスワードを8~16文字で入力してください
              div {{ textCount }}/16
              div.small ※大小英数字8~16文字で入力してください
            .form-group
              p 登録には
                router-link(to="/info/terms") 利用規約
                |と
                router-link(to="/info/contact-privacy" target="_blank" rel="noopener") 個人情報の取り扱いについて
                |への同意が必要です
            input.btn.btn-primary(type="submit" value="規約に同意して次へ" v-bind:disabled="isDisabled")
</template>

<script>
import axios from 'axios'

import {
  Config,
  CognitoIdentityCredentials
} from 'aws-sdk'
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute
} from 'amazon-cognito-identity-js'

export default {
  data() {
    return {
      message: '',
      mail: '',
      password: '',
      isDisabled: false,
      errMessage: false,
    }
  },
  created() {
    const poolData = {
      UserPoolId : process.env.VUE_APP_COGNITO_USER_POOL_ID,
      ClientId : process.env.VUE_APP_COGNITO_CLIENT_ID
    }
    this.userPool = new CognitoUserPool(poolData)
  },
  methods: {
    toHalfWidth(value) {
      return value.replace(/[Ａ-Ｚａ-ｚ０-９．＠＋－]/g, s => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      })
    },
    exec(e) {
      this.isDisabled = true

      this.message = ''

      this.errMessage = false

      //エラー表示
      if (e.target.checkValidity() === false) {
        e.target.classList.add('was-validated')
        e.stopPropagation()
        this.isDisabled = false
        return
      }

      this.mail = this.mail.trim()
    
      this.mail = this.toHalfWidth(this.mail)

      let userData = {
        Username : this.mail,
        Pool : this.userPool
      }
      
      this.$user.cognito = new CognitoUser(userData)

      // サインアップ---ここから
      const dataEmail = {
        Name : 'email',
        Value : this.mail.toLowerCase() // メールアドレスのテキストボックス値に変更
      }
      let attributeEmail = new CognitoUserAttribute(dataEmail)

      //配列の宣言
      let  attributeList = []

      // attributeEmail を attributeListに追加
      attributeList.push(attributeEmail)

      this.userPool.signUp(dataEmail.Value, this.password, attributeList, null, (err, result) => {
        if(err) {
          //処理結果Error
          if(err.code == 'InvalidParameterException') {
            this.message = 'メールアドレスが正しくありません。'
          }else if(err.code == 'InvalidPasswordException') {
            this.message = 'パスワードが正しく入力されていません。大小英数字8~16文字で入力してください。'
          }else if(err.code == 'TooManyRequestsException') {
            this.message = 'しばらく時間をおいてから再度お試しください。'
          }else if(err.code == "UsernameExistsException") {
            this.message = 'すでにユーザー登録済みのメールアドレスです。'
          }else {
            this.errMessage = true
          }
          this.isDisabled = false
          return
        }
        // 処理結果OK
        //ユーザー追加
        this.createUser(result)
      })
      // サインアップ---ここまで
    },
    //ユーザー追加
    createUser(result) {
      const url = process.env.VUE_APP_API_GATEWAY + '/api-user'
      const params = {
        mail: this.mail,
        pw: this.password,
        userId: result.userSub,
      }
      axios.post(url, params, {
      })
        .then(res => {
          this.$router.push({ name:'register_user_input_auth' , params: { mail: this.mail, password: this.password}}) //ページ移動
        })
        .catch(err => {
          this.message = err.message
          console.log(err)
        })
    },
  },
  computed: {
    textCount() { return this.password.length },
  }
}
</script>